/**
 *  ┌─────────────────────────────────────────────────────────────┐
 *  │┌───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┐│
 *  ││Esc│!1 │@2 │#3 │$4 │%5 │^6 │&7 │*8 │(9 │)0 │_- │+= │|\ │`~ ││
 *  │├───┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴───┤│
 *  ││ Tab │ Q │ W │ E │ R │ T │ Y │ U │ I │ O │ P │{[ │}] │ BS  ││
 *  │├─────┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴─────┤│
 *  ││ Ctrl │ A │ S │ D │ F │ G │ H │ J │ K │ L │: ;│" '│ Enter  ││
 *  │├──────┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴────┬───┤│
 *  ││ Shift  │ Z │ X │ C │ V │ B │ N │ M │< ,│> .│? /│Shift │Fn ││
 *  │└─────┬──┴┬──┴──┬┴───┴───┴───┴───┴───┴──┬┴───┴┬──┴┬─────┴───┘│
 *  │      │Fn │ Alt │         Space         │ Alt │Win│   HHKB   │
 *  │      └───┴─────┴───────────────────────┴─────┴───┘          │
 *  └─────────────────────────────────────────────────────────────┘
 *
 * Description:
 * Author: wangjian@12552
 * Date: 2023-07-03 15:43:05
 * LastEditors: wangjian@12552
 * LastEditTime: 2023-07-03 18:15:27
 * FilePath: \xiaoyutongxue\src\i18n\sp.js
 */

export default {
  about: {
    title: 'TDF Development Framework',
    introduce:
      'TDF Base is a development framework based on SpringBoot. It is developed by Taiji research and Development Institute and mainly provides common system functions such as users, user groups, roles, permissions, organizations, menus, data dictionaries, logs, etc. The research results are open source for internal use within Taiji.',
    doc: 'Development Document',
    code: 'Source Code Address',
  },
  layout: {
    layoutConfig: 'Layout Configuration',
    workbenchMang: 'Workbench management',
    quickInletConfig: 'Quick Access Configuration',
    chinese: 'Chinese',
    avatar: 'Avatar information',
    company: 'Company',
    email: 'Correo electrónico',
    home: 'CASA',
    moreInfo: 'More information',
    main: 'Enter the portal',
    switch: 'switch',
    up: 'As',
  },
};
