const Mixin = {
  props: {
    /**
     * tiuFile混入信息开始
     */
    /**
     * tiuFile混入信息结束
     */
  },
  computed: {
    /*******
     * @description: 判断是否是移动端
     * @return {*}
     * @Author: wdd
     * @Date: 2022-08-01 10:54:11
     */
    isMobile() {
      // 移动设置、窗口小于600、微信环境
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        ) ||
        window.matchMedia('(max-width: 400px)').matches ||
        window.navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1
      ) {
        // 当前设备是移动设备
        return true;
      }
      return false;
    },
    /*******
     * @description: 判断是否是ie
     * @return {*}
     * @Author: wangjian@12552
     * @Date: 2023-03-21 16:48:59
     */
    isMinxinIE() {
      if (!!window.ActiveXObject || 'ActiveXObject' in window) return true;
      else return false;
    },
    /*******
     * @description: 判断是否是mac系统
     * @return {*}
     * @Author: wdd
     * @Date: 2022-08-01 10:54:34
     */
    isMac() {
      var agent = navigator.userAgent.toLowerCase();
      let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
        console.log('这是windows32位系统');
        return false;
      }
      if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
        console.log('这是windows64位系统');
        return false;
      }
      if (isMac) {
        console.log('这是mac系统');
        return true;
      }
    },
  },

  methods: {
    uuid() {
      let crypto = window.crypto || window.msCrypto;
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
  },
};
export default Mixin;
